/*
  globals
  document,
  HTMLFormElement,
  HTMLInputElement,
  window
*/

import {
  userSystemsRequestClient,
  showAndFocusElement,
  hideElement,
  showElement,
  getRedirectPath,
  handleRedirect,
  userFormError,
  disableSubmit,
  resetSubmit,
} from './utils';
import {
  USYS_DATA_ATTRS,
  USYS_DOM_CLASS_NAMES,
  USYS_FORM_TYPES,
  USYS_INPUT_TYPES,
} from '@packages/systems/users/constants';
import {signupMutation, verifyEmailMutation} from './mutations';
import {
  getCommonFields,
  getCustomFields,
  getFieldsAsTypeKeys,
  getFieldValueById,
} from './fields';
import {renderTurnstileCaptcha} from './turnstileCaptcha';

const signupFormQuerySelector = `form[${USYS_DATA_ATTRS.formType}="${USYS_FORM_TYPES.signup}"]`;

const verificationMessage = document.querySelector(
  `.${USYS_DOM_CLASS_NAMES.formVerfication}`
);

function getSignupForms() {
  const signupForms = document.querySelectorAll(signupFormQuerySelector);
  return Array.prototype.slice
    .call(signupForms)
    .filter((signupForm) => signupForm instanceof HTMLFormElement);
}

function handleUserInvite(email: string) {
  // email is for DISPLAY use ONLY for security reasons
  const form = document.querySelector(signupFormQuerySelector);

  if (!(form instanceof HTMLFormElement)) {
    return;
  }

  const emailInput = form.querySelector(
    `input[${USYS_DATA_ATTRS.inputType}="${USYS_INPUT_TYPES.email}"]`
  );

  if (!(emailInput instanceof HTMLInputElement)) {
    return;
  }

  // disable email input
  emailInput.disabled = true;
  emailInput.classList.add('w-input-disabled');

  // and set the value to the user's email
  emailInput.value = email;
}

// users are sent to the sign up page to complete verification
function handleEmailVerification(
  token: string,
  errorState: null | HTMLElement
) {
  const form = document.querySelector(signupFormQuerySelector);
  // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null | undefined'.
  hideElement(form);

  asyncVerifyEmailToken(token)
    .then(() => {
      const successMessage = document.querySelector(
        `.${USYS_DOM_CLASS_NAMES.formSuccess}`
      );
      const redirectAnchor = document.querySelector(
        `[${USYS_DATA_ATTRS.redirectUrl}] a`
      );
      const redirectPath = getRedirectPath();

      // If redirect param exists, use that path for the redirect anchor href
      if (redirectPath && redirectAnchor) {
        redirectAnchor.setAttribute('href', encodeURIComponent(redirectPath));
      }

      // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null | undefined'.
      showElement(successMessage);

      // If redirect anchor exists, use that as default redirect path
      handleRedirect(redirectAnchor?.getAttribute('href') ?? '/', true);
    })
    .catch((error) => {
      // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null | undefined'.
      showElement(verificationMessage);
      // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null'.
      userFormError(form, errorState, 'SIGNUP')(error);
    });
}

export function handleSignUpForms() {
  // we will check to see if there is a token in the link to initiate the verification flow
  // otherwise, the regular sign-up form will be shown
  const params = new URLSearchParams(window.location.search);
  const inviteToken = params.get('inviteToken') || '';
  const verifyToken = params.get('verifyToken') || '';
  const errorState = document.querySelector(`[${USYS_DATA_ATTRS.formError}]`);
  let turnstileScript: HTMLScriptElement | null = null;

  getSignupForms().forEach((signupForm) => {
    const submitButton = signupForm.querySelector('input[type="submit"]');
    const sendSubmitData = (captchaToken: string | null) => {
      const submitText = disableSubmit(submitButton);
      const commonFields = getCommonFields(signupForm);
      const customFields = getCustomFields(signupForm);

      // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null | undefined'.
      hideElement(errorState);

      asyncSignUpUser(
        getFieldValueById('email', commonFields) || '',
        getFieldValueById('name', commonFields) || '',
        getFieldValueById('password', commonFields) || '',
        getFieldValueById('accept-privacy', commonFields) || false,
        getFieldValueById('accept-communications', commonFields) || false,
        customFields,
        inviteToken,
        captchaToken
      )
        .then(() => {
          if (inviteToken) {
            // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Location | (string & Location)'.
            window.location = '/log-in';
          } else {
            hideElement(signupForm);
            // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null | undefined'.
            showAndFocusElement(verificationMessage);
          }
        })
        // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null'.
        .catch(userFormError(signupForm, errorState, 'SIGNUP'))
        .finally(() => {
          // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null'.
          resetSubmit(submitButton, submitText);
        });
    };

    const captchaSiteKey = signupForm.getAttribute('wf-captcha-site-key');
    const captchaMode = signupForm.getAttribute('wf-captcha-mode');
    if (captchaSiteKey && captchaMode && !turnstileScript) {
      // @ts-expect-error - TS18047 - 'submitButton' is possibly 'null'.
      submitButton.setAttribute('disabled', 'true');
      turnstileScript = document.createElement('script');
      turnstileScript.src =
        'https://challenges.cloudflare.com/turnstile/v0/api.js';
      document.head.appendChild(turnstileScript);
      turnstileScript.onload = () => {
        signupForm.addEventListener('submit', (e: SubmitEvent) => {
          e.preventDefault();
          renderTurnstileCaptcha(captchaSiteKey, captchaMode, sendSubmitData);
        });
        // @ts-expect-error - TS18047 - 'submitButton' is possibly 'null'.
        submitButton.removeAttribute('disabled');
      };
    } else {
      signupForm.addEventListener('submit', (e: SubmitEvent) => {
        e.preventDefault();
        sendSubmitData(null);
      });
    }

    if (inviteToken) {
      const email = params.get('email') || '';
      handleUserInvite(email);
    }

    if (verifyToken) {
      // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null'.
      handleEmailVerification(verifyToken, errorState);
    }
  });
}

export function asyncSignUpUser(
  email: string,
  name: string | null | undefined = '',
  password: string,
  acceptPrivacy: boolean,
  acceptCommunications: boolean,
  customFields: any,
  inviteToken: string | null,
  captchaToken: string | null
) {
  const variables = {
    email,
    name,
    acceptPrivacy,
    acceptCommunications,
    authPassword: password,
    data: getFieldsAsTypeKeys(customFields),
    inviteToken: inviteToken || undefined,
    captchaToken: captchaToken || undefined,
    redirectPath: getRedirectPath(),
  } as const;

  return userSystemsRequestClient.mutate({
    mutation: signupMutation,
    variables,
  });
}

function asyncVerifyEmailToken(verifyToken: string) {
  return userSystemsRequestClient.mutate({
    mutation: verifyEmailMutation,
    variables: {
      verifyToken,
      redirectPath: getRedirectPath(),
    },
  });
}
