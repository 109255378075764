/* globals
 window
 */

import escape from 'lodash/escape';
import get from 'lodash/get';
import {transformers} from '@packages/systems/dynamo/utils/Transformers';
import type {BindingTransformer} from '@packages/systems/dynamo/binding-types';
import {applyConditionToNode} from '@packages/systems/dynamo/utils/RenderingUtils';
import {SHARED_ALLOWED_FIELD_TYPES} from '@packages/systems/dynamo/constants';
import {
  WF_BINDING_DATA_KEY,
  WF_CONDITION_DATA_KEY,
} from '@packages/systems/commerce/constants';
import {
  USYS_DATA_ATTRS,
  RESERVED_USER_PREFIX,
  KEY_FROM_RESERVED_USER_FIELD,
} from '@packages/systems/users/constants';
import {setUserFileKey} from '@packages/systems/users/utils/universalUtils';
import {addHiddenClass, removeHiddenClass} from './utils';

type FieldType = string;

type Bindings = [Partial<Record<BindingProperty, Binding>>];
type BindingProperty = keyof typeof mutators;
type Binding = {
  filter: BindingTransformer;
  type: FieldType;
  dataPath: string;
  timezone?: string;
  pageLinkHrefPrefix?: string;
  collectionSlugMap?: {
    [key: string]: string;
  };
};

const getPropertyMutator = (bindingProperty: BindingProperty) => {
  if (typeof mutators[bindingProperty] === 'function') {
    return mutators[bindingProperty];
  }
  return null;
};

const mutators = {
  innerHTML: (node: Element, type: string, value: unknown) => {
    const valueString = value != null ? String(value) : '';
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ PlainText: string; HighlightedText: string; RichText: string; Number: string; Video: string; Option: string; Date: string; Phone: string; Email: string; CommercePrice: string; Link: string; ImageRef: boolean; FileRef: boolean; ItemRef: boolean; CommercePropValues: string; }'.
    if (SHARED_ALLOWED_FIELD_TYPES.innerHTML[type] === 'innerHTML') {
      node.innerHTML = valueString;
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ PlainText: string; HighlightedText: string; RichText: string; Number: string; Video: string; Option: string; Date: string; Phone: string; Email: string; CommercePrice: string; Link: string; ImageRef: boolean; FileRef: boolean; ItemRef: boolean; CommercePropValues: string; }'.
    } else if (SHARED_ALLOWED_FIELD_TYPES.innerHTML[type] === 'innerText') {
      node.innerHTML = escape(valueString);
    }

    if (node.innerHTML) {
      node.classList.remove('w-dyn-bind-empty');
    }
  },
  src: (node: Element, type: string, value: any) => {
    if (value && value.url) {
      node.setAttribute('src', value.url);
    }
    node.classList.remove('w-dyn-bind-empty');
  },
} as const;

const bindDataToNode = (
  node: Element,
  data: Record<any, any>,
  bindings: Bindings
) => {
  bindings.forEach((binding) => {
    Object.keys(binding).forEach((bindingProperty) => {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<"innerHTML" | "src", Binding>>'.
      const bindingValue = binding[bindingProperty];

      const {dataPath, filter, timezone, type} = bindingValue;
      const rawValue = get(data, dataPath);

      const transformedValue = transformers(rawValue, filter, {
        timezone,
        collectionSlugMap: {},
        // @ts-expect-error - TS2339 - Property '__WEBFLOW_CURRENCY_SETTINGS' does not exist on type 'Window & typeof globalThis'.
        currencySettings: window.__WEBFLOW_CURRENCY_SETTINGS,
      });

      // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"innerHTML" | "src"'.
      const propertyMutator = getPropertyMutator(bindingProperty);

      if (propertyMutator) {
        propertyMutator(node, type, transformedValue);
      }
    });
  });
};

export function applyBindingsAndConditionalVisibility(
  node: Element,
  data: any
) {
  // Apply bindings
  if (node.hasAttribute(WF_BINDING_DATA_KEY)) {
    const bindingsStr = node.getAttribute(WF_BINDING_DATA_KEY) || '';
    const bindings = JSON.parse(decodeURIComponent(bindingsStr));
    if (bindings) {
      bindDataToNode(node, data, bindings);
    }
  }
  // Apply conditional visibility
  if (node.hasAttribute(WF_CONDITION_DATA_KEY)) {
    const conditionsStr = node.getAttribute(WF_CONDITION_DATA_KEY) || '';
    const conditionData = JSON.parse(decodeURIComponent(conditionsStr));
    if (conditionData) {
      applyConditionToNode(node, data, conditionData);
    }
  }
}

type ElementWithInputProperties = Element & {
  value?: string;
  type?: string;
  checked?: boolean;
  click?: () => void;
};

function getFirstAncestor(
  element: ElementWithInputProperties,

  pred: (element: never) => never
) {
  if (element.parentNode === null) {
    return null;
  }
  // @ts-expect-error - TS2345 - Argument of type 'ElementWithInputProperties' is not assignable to parameter of type 'never'.
  if (pred(element)) {
    return element;
  }
  // @ts-expect-error - TS2345 - Argument of type 'ParentNode' is not assignable to parameter of type 'ElementWithInputProperties'.
  return getFirstAncestor(element.parentNode, pred);
}

function hasFormFileUploadWrapperClass(element: any) {
  return element.classList.contains('w-file-upload');
}

function setFileUploadValue(node: ElementWithInputProperties, fileId: string) {
  if (!fileId) return;
  setUserFileKey(node, fileId);

  const formFileUploadWrapper = getFirstAncestor(
    node,
    // @ts-expect-error - TS2345 - Argument of type '(element: any) => any' is not assignable to parameter of type '(element: never) => never'.
    hasFormFileUploadWrapperClass
  );
  if (formFileUploadWrapper === null) return;

  const formFileDefault = formFileUploadWrapper.querySelector(
    '.w-file-upload-default'
  );
  const formFileSuccess = formFileUploadWrapper.querySelector(
    '.w-file-upload-success'
  );
  const formFileError = formFileUploadWrapper.querySelector(
    '.w-file-upload-error'
  );
  const formFileUploading = formFileUploadWrapper.querySelector(
    '.w-file-upload-uploading'
  );
  // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null'.
  addHiddenClass(formFileDefault);
  // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null'.
  addHiddenClass(formFileError);
  // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null'.
  addHiddenClass(formFileUploading);
  // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'HTMLElement | null'.
  removeHiddenClass(formFileSuccess);
}

export function applyUserAccountData(
  node: ElementWithInputProperties,
  userData: any
) {
  // Apply user name, checkbox, and custom fields
  if (node.hasAttribute(USYS_DATA_ATTRS.field)) {
    const field = node.getAttribute(USYS_DATA_ATTRS.field) || '';
    const fieldType = node.getAttribute(USYS_DATA_ATTRS.fieldType) || '';
    if (fieldType === 'Option') {
      node.value = get(userData, [`f_${field}`, 'slug'], '');
      return;
    }
    if (fieldType === 'FileRef') {
      setFileUploadValue(node, get(userData, [`f_${field}`, 'id'], ''));
      return;
    }
    const dataPath =
      field && field.includes(RESERVED_USER_PREFIX)
        ? KEY_FROM_RESERVED_USER_FIELD[field]
        : `f_${field}`;
    // @ts-expect-error - TS2769 - No overload matches this call.
    const value = get(userData, [dataPath], '');

    if (
      node.type === 'checkbox' &&
      node.checked !== Boolean(value) &&
      node.click
    ) {
      // Set the checkbox to the right `checked` value, but also trigger the onChange event
      node.click();
      return;
    }

    node.value = value;
  }
  // Apply user email, if not empty
  if (node.hasAttribute(USYS_DATA_ATTRS.inputType)) {
    const dataPath = node.getAttribute(USYS_DATA_ATTRS.inputType) || '';
    const value = get(userData, [dataPath], '');
    if (value) {
      node.value = value;
    }
  }
}
